<template>
  <div class="print_template_list">
    <div>{{templateName}}</div>
    <div  class="print_template_grid">
      <div class="print_template_item"
        v-for="i in templateList"
        :key="i.id">
          <div>
            <span class="text-ellipsis" :title="i.name">
              {{i.name}}
            </span>
            <span class=" ml-1 flex-fill">
            <i class="icon iconfont icon-bianji1 text-disable cursor-pointer"   @click="handleAction('EDITNAME',i)"></i>
            </span>
          </div>
          <div>
             <el-tag size="mini" effect="dark" type="primary">{{i.paperSize}}</el-tag>
             <el-tag size="mini" effect="dark" class="ml-1" type="primary">{{i.paperDirection === 'TRANSVERSE'?'横向':'纵向'}}</el-tag>
          </div>
          <div><span>最近更新：{{i.updateUserName}} {{i.lastUpdateTime | date-format}}</span></div>
          <div>
            <span>
              <el-tooltip  effect="dark" content="复制模板" placement="top" v-auth="'systemConfig:workOrder:print:copy'">
                <i  class="el-icon-copy-document text-primary cursor-pointer"  @click="handleAction('COPY',i)"></i>
              </el-tooltip>
              <el-tooltip  effect="dark" content="模板配置" placement="top" v-auth="'systemConfig:workOrder:print:edit'">
                <i  class="icon iconfont icon-xiugai ml-2  text-primary cursor-pointer"   @click="handleAction('EDITTEMPLATE',i)"></i>
              </el-tooltip>
              <el-tooltip  effect="dark" content="删除" placement="top" v-auth="'systemConfig:workOrder:print:del'">
                <i  class="icon iconfont icon-shanchu ml-2 text-danger cursor-pointer"    @click="handleAction('DELETE',i)"></i>
              </el-tooltip>
            </span>
          </div>
        </div>
        <div class="print_template_item print_template_item_add cursor-pointer"
          @click="handleAction('NEW')"
          v-auth="'systemConfig:workOrder:print:add'">
          <i class="el-icon-plus text-primary "></i>
        </div>
    </div>
  </div>
</template>
<script>
import { getPrintTemplateList, editPrintTemplateName, deletePrintTemplate } from '@/api/formSetting';
export default {
  name: 'PrintTemplateList',
  props: {
    templateCode: String,
    templateName: String,
  },
  data () {
    return {
      templateList: [],
      tableButtons: [
        {
          name: '新增打印模板', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          click: ()=> {
            this.$router.push({
              name: 'FORM_PRINT_TEMPLATE_DETAIL',
            });
          },
        },
      ],
    };
  },
  methods: {
    getTemplateList () {
      getPrintTemplateList({
        customizeBusinessTypeCode: this.templateCode,
      }).then(({body})=>{
        this.templateList = body || [];
      });
    },
    handleAction (cmd, row) {
      if (cmd === 'DELETE') {
        this.$confirm(`确定删除${row.name}吗？`, '提示', {
          type: 'waring',
        }).then(()=>{
          deletePrintTemplate({
            id: row.id,
          }).then(()=>{
            this.$message.success('删除成功');
            this.getTemplateList();
          });
        });
      } else if (cmd === 'NEW') {
        this.$router.push({
          name: 'FORM_PRINT_TEMPLATE_DETAIL',
          query: {
            templateCode: this.templateCode,
          },
          params: {
            action: 'new',
          },
        });
      } else if (cmd === 'COPY') {
        this.$router.push({
          name: 'FORM_PRINT_TEMPLATE_DETAIL',
          query: {
            templateCode: this.templateCode,
            id: row.id,
          },
          params: {
            action: 'copy',
          },
        });
      } else if (cmd === 'EDITTEMPLATE') {
        this.$router.push({
          name: 'FORM_PRINT_TEMPLATE_DETAIL',
          query: {
            templateCode: this.templateCode,
            id: row.id,
          },
          params: {
            action: 'edit',
          },
        });
      } else if (cmd === 'EDITNAME') {
        this.$prompt('', '修改名称', {
          confirmButtonText: '修改',
          cancelButtonText: '取消',
          inputValue: row.name,
          inputErrorMessage: '邮箱格式不正确',
        }).then(({ value }) => {
          editPrintTemplateName({
            id: row.id,
            name: value,
          }).then(()=>{
            this.$message.success('修改成功');
            this.getTemplateList();
          });
        });
      }
    },
  },
  created () {
    this.getTemplateList();
  },
};
</script>

<style lang="scss" scoped>
  .print_template_list {
    height: 100%;
    box-sizing: border-box;
    background: white;
    display: flex;
    flex-direction: column;
    &>div:first-child{
      height: 48px;
      padding: 0 12px;
      background: #FFFFFF;
      border-bottom: 1px solid rgba(36,40,50,0.16);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #242832;
      line-height: 14px;
    }
    .print_template_grid {
      padding: 24px 12px;
      flex: 1;
      display: grid;
      justify-content: space-around;
      grid-template-columns: repeat(auto-fill, 290px);
      grid-template-rows: repeat(auto-fill, 130px);
      grid-gap: 16px;
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      .print_template_item {
        position: relative;
        font-size: 14px;
        line-height: 1;
        box-sizing: border-box;
        width: 290px;
        height: 130px;
        padding:16px;
        box-shadow: 0px 2px 4px 0px rgba(36,40,50,0.1000);
        border-radius: 4px;
        border: 1px solid rgba(113,121,141,0.0800);
        display: flex;
        flex-direction: column;
        transition-duration: 0.3s;
      transition-property: transform;
        .iconfont{
          font-size: 14px;
        }

        &:hover {
          // animation: pulse; /* referring directly to the animation's @keyframe declaration */
          // animation-duration: 1s; /* don't forget to set a duration! */
          transform: scale(1.05);
        }

        &.print_template_item_add{
          justify-content: center;
          align-items: center;
          font-size: 36px;
        }
        &>div {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

            &:nth-child(1){
              &>span:first-child{
                font-weight: bolder;
                color: #242832;
              };
              justify-content: space-between;
            }

            &:nth-child(2){
              padding-top: 12px;
              padding-bottom: 12px;

              /deep/ .el-tag  {
                border-radius: 2px;
              }
            }
            &:nth-child(3) {
              font-weight: 400;
              padding-bottom: 12px;
              color: rgba(36,40,50,0.7000);
              &>span{
                text-overflow:ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
            &:nth-child(4){
              position: absolute;
              right: 30px;
              bottom: 10px;
              justify-content: flex-end;
              font-size: 14px;
            }
        }
      }
    }
  }
</style>
